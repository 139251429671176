<template>
  <div class="admin-profile-info">
    <section class="panel is-primary">
      <h1 class="title is-6 panel-heading mb-0">Общая информация</h1>
      <div class="box">
        <div class="columns">
          <div class="column">
            <h6>Фамилия:</h6>
            <p>{{ userData.lastName }}</p>
          </div>
          <div class="column">
            <h6>Имя:</h6>
            <p>{{ userData.firstName }}</p>
          </div>
          <div class="column">
            <h6>Отчество:</h6>
            <p>{{ userData.patronymic }}</p>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <h6>Роль:</h6>
            <p>{{ convertRole(userData.role) }}</p>
          </div>
          <div class="column">
            <h6>Email:</h6>
            <p>{{ userData.email }}</p>
          </div>
          <div class="column">
            <h6>Баланс:</h6>
            <div class="balance">
              <p>
                {{ convertDigits(userData.balance) }}
                <b-icon icon="ruble-sign" size="is-small"></b-icon>
              </p>
              <b-button
                size="is-small"
                type="is-info"
                @click.prevent="isModalCreatePaymentActive = true"
                >Пополнить</b-button
              >
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <h6>Количество сотрудников:</h6>
            <p>{{ userData.totalEmployees }}</p>
          </div>
          <div class="column">
            <h6>Количество клиентов:</h6>
            <p>{{ userData.totalClients }}</p>
          </div>
          <div class="column">
            <h6>Дисковое пространство:</h6>
            <p>{{ userData.diskSpace }}</p>
          </div>
        </div>
        <div class="columns">
          <!-- <div class="column">
            <h6>Бесплатных СМС:</h6>
            <p>{{ userData.freeSms }}</p>
          </div>
          <div class="column">
            <h6>Отправлено СМС за месяц:</h6>
            <p>{{ userData.monthSmsSend }}</p>
          </div> -->
          <div class="column">
            <h6>ID аккаунта:</h6>
            <p>{{ userData.accountId }}</p>
          </div>
          <div class="column">
            <h6>Текущий тариф:</h6>
            <p>{{ converSubscriptionType(userData.subscriptionType) }}</p>
          </div>
          <div class="column">
            <h6>Статус подписки:</h6>
            <p>
              {{
                Number(userData.subscriptionStatus)
                  ? 'Активна'
                  : 'Заблокирована'
              }}
            </p>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <h6>Дата окончания подписки:</h6>
            <p>{{ userData.subscriptionDateEnd }}</p>
          </div>
          <div class="column">
            <h6>Реферальная ссылка:</h6>
            <p>
              <b-button
                icon-left="copy"
                type="is-info is-light"
                size="is-small"
                @click.prevent="copyTextToClipboard(userData.refLink)"
              >
                Копировать ссылку
              </b-button>
            </p>
          </div>
          <div class="column">
            <h6>Вознаграждение за пользователя:</h6>
            <p>
              {{ userData.refAmount }}
              <b-icon icon="ruble-sign" size="is-small"></b-icon>
            </p>
          </div>
        </div>
      </div>
      <div class="button-wrapper">
        <b-button
          icon-left="unlock-alt"
          type="is-info"
          @click.prevent="isModalChangePasswordActive = true"
          >Сменить пароль</b-button
        >
      </div>
      <b-modal
        class="change-password"
        has-modal-card
        v-model="isModalChangePasswordActive"
      >
        <template #default="props">
          <ModalChangePassword @close="props.close" />
        </template>
      </b-modal>
      <b-modal
        class="create-payment"
        has-modal-card
        v-model="isModalCreatePaymentActive"
      >
        <template #default="props">
          <ModalCreatePayment :minSum="userData.minSum" @close="props.close" />
        </template>
      </b-modal>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ModalChangePassword from '@/components/profile/ModalChangePassword';
import ModalCreatePayment from '@/components/profile/ModalCreatePayment';

export default {
  name: 'AdminProfileInfo',
  data() {
    return {
      userData: {},
      isModalChangePasswordActive: false,
      isModalCreatePaymentActive: false,
    };
  },
  async created() {
    this.$store.commit('toggleLoader', true);
    await this.$store.dispatch('profileInfo');
    this.$store.commit('toggleLoader', false);

    (function (m, e, t, r, i, k, a) {
      m[i] =
        m[i] ||
        function () {
          (m[i].a = m[i].a || []).push(arguments);
        };
      m[i].l = 1 * new Date();
      (k = e.createElement(t)),
        (a = e.getElementsByTagName(t)[0]),
        (k.async = 1),
        (k.src = r),
        a.parentNode.insertBefore(k, a);
    })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

    /*global ym*/
    ym(81544822, 'init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
    });
  },
  computed: mapState({
    user: (state) => {
      return state.user;
    },
  }),
  watch: {
    user(data) {
      this.userData = data;

      if (!data.subscriptionStatus) {
        setTimeout(() => {
          this.$store.dispatch('profileInfo');
        }, 5000);
      }
    },
  },
  methods: {
    convertDigits(val) {
      if (val !== undefined && val !== null) {
        return parseFloat(val).toLocaleString('ru-RU');
      }

      return '';
    },
    converSubscriptionType(type) {
      let subscriptionTypeText = '';

      switch (Number(type)) {
        case 1:
          subscriptionTypeText = 'Стартовый';
          break;

        case 2:
          subscriptionTypeText = 'Профи';
          break;

        case 3:
          subscriptionTypeText = 'Бизнес';
          break;

        default:
          break;
      }

      return subscriptionTypeText;
    },
    convertRole(role) {
      let roleText = '';

      switch (Number(role)) {
        case 1:
          roleText = 'Супер-админ';
          break;
        case 2:
          roleText = 'Администратор';
          break;
        case 3:
          roleText = 'Сотрудник';
          break;

        default:
          break;
      }

      return roleText;
    },
    fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement('textarea');
      textArea.value = text;

      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';
      textArea.style.opacity = '0';
      textArea.style.zIndex = '-999';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');

        if (successful) {
          this.$buefy.toast.open({
            message: 'Ссылка успешно скопирована!',
            type: 'is-success',
            duration: 5000,
            position: 'is-bottom-right',
          });
        } else {
          this.$buefy.toast.open({
            message: 'Что-то пошло не так. Обратитесь к разработчикам',
            type: 'is-danger',
            duration: 5000,
            position: 'is-bottom-right',
          });
        }
      } catch (err) {
        this.$buefy.toast.open({
          message: 'Что-то пошло не так. Обратитесь к разработчикам',
          type: 'is-danger',
          duration: 5000,
          position: 'is-bottom-right',
        });
      }

      document.body.removeChild(textArea);
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        () => {
          this.$buefy.toast.open({
            message: 'Ссылка успешно скопирована!',
            type: 'is-success',
            duration: 5000,
            position: 'is-bottom-right',
          });
        },
        () => {
          this.$buefy.toast.open({
            message: 'Что-то пошло не так. Обратитесь к разработчикам',
            type: 'is-danger',
            duration: 5000,
            position: 'is-bottom-right',
          });
        }
      );
    },
  },
  components: { ModalChangePassword, ModalCreatePayment },
};
</script>

<style lang="scss">
.admin-profile-info {
  height: 100%;

  section {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  h6 {
    font-weight: bold;
  }

  .balance {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: auto;
    padding: 20px;
  }
}
</style>
